body {
  margin: 0;
  font-family: "Helvetica Neue LT Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background {
  filter: opacity(0.25);
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.background img {
  height: 100%;
}

.button {
  background-color: #e4ee51;
  border: none;
  color: black;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Helvetica Neue LT Pro;
  font-size: 20px;
  font-weight: 700;
}
