.login-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 20%;
}

.login-form {
    padding: 35px;
    border-radius: 0px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: white;
}

.form-group input {
    width: calc(100% - 42px);
    padding: 20px;
    border: none;
    background-color: #262626;
    caret-color: #e4ee51;
    font-size: 20px;
    font-weight: 500;
    color: #e4ee51;
}

.form-group input::placeholder {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
}

.login-button {
    width: 100%;
    margin-top: 20px;
}
