@import url("https://fonts.cdnfonts.com/css/helvetica-neue-lt-pro");
:root {
  --svh: svh;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #1a1a1a; /* Dark background color */
  overflow: hidden; /* Prevent scrolling */
}

.App2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100svh - 150px); /* Full viewport height */
  width: 100%; /* Full viewport width */
  overflow: hidden; /* Prevent scrolling */
}

.App {
  background-image: url("./assets/login-background.png");
  height: 100svh;
  background-size: cover;
  overflow: hidden;
}

.App-header {
  text-align: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden; /* Prevent scrolling */
}

.title-text-yellow {
  font-size: 40px;
  font-weight: 750;
  color: #e4ee51;
  margin-bottom: 25px;
}

.text-center {
  text-align: center;
}
