.item-container {
    padding: 15px;
    background-color: #212121;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item-name {
    font-size: 24px;
    color: #e4ee51;
    font-weight: 700;
    width: 70%;
    text-align: left;
}

.item-number {
    font-size: 24px;
    color: white;
    font-weight: 500;
}
