.homepage-container {
    height: 100%;
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: calc(100% - 72px);
    top: 20%;
}

.undo-container {
    width: calc(100% - 72px);
    color: #e4ee51;
    text-align: left;
    font-weight: 500;
    position: absolute;
    bottom: 30%;
    padding: 35px;
}

.undo-container span {
    color: white;
    font-weight: 500;
}

.loading {
    width: 100%;
    height: 12px;
    background-color: #e4ee51;
    margin-top: 10px;
    margin-bottom: 30px;
    animation:
        shrink 30s forwards,
        disappear 1s 30s forwards;
}

.undo-button {
    width: 100%;
    background-color: white !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logout-button {
    justify-content: center;
    color: white;
    text-align: center;
    width: fit-content;
    margin: auto;
    font-size: 20px;
    font-weight: 700;
}

@keyframes shrink {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

@keyframes disappear {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
