.input-manual-container {
    height: 100svh;
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: calc(100% - 72px);
    position: relative;
    top: 5%;
}

.input-manual-form {
    width: 100%;
}

.input-manual-form-group input {
    width: calc(100% - 40px);
    padding: 20px;
    border: none;
    background-color: #262626;
    caret-color: #e4ee51;
    font-size: 20px;
    font-weight: 500;
    color: #e4ee51;
}

.input-manual-form-group input::placeholder {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
}

.input-manual-submit {
    margin-top: 35px;
    width: 100%;
}

.input-manual-cancel {
    position: absolute;
    bottom: 30%;
    margin: 35px;
    background-color: white !important;
    width: calc(100% - 70px);
}
