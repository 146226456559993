.qr-reader {
    width: 100%;
    max-width: 430px;
    /* height: calc(100% - 220px); */
    height: 100svh;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.scanned-result {
    position: absolute;
    top: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 4px;
    z-index: 999;
}

.btn-container {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 3%;
    margin: 35px;
}

.close-scanner-button {
    background-color: white !important;
    width: 50%;
}

.manual-input-button {
    background-color: #e4ee51 !important;
    width: 50%;
}
