.text-big {
    padding: 25px 50px;
}

.text-big img[alt="logo"] {
    width: 100%;
}

.text-small {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-small img[alt="logo"] {
    width: 50%;
}

.text-small img[alt="arrowLeft"] {
    width: auto;
}
