.result-container {
    width: 100%;
    height: 100%;
    text-align: center;
}

.player-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
}

.player-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 12px solid #e4ee51;
}

.player-img img {
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: center;
    left: 0;
}

.player-name {
    font-size: 5svh;
    color: #e4ee51;
    font-weight: 700;
    padding: 0 35px;
}

.resources-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
    height: 50%;
    overflow: scroll;
}

.no-valid-code {
    color: white;
    font-size: 40px;
    font-weight: bold;
}
